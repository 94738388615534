import { logToSentry } from '@jsmdg/react-fragment-scripts/fragment';
import { type ProductAttributes } from '../../shared/types/search';
import { type QuickFilter } from '../types';

const getServerAttributes = (productAttributes?: ProductAttributes): QuickFilter[] => {
    try {
        if (productAttributes) {
            return Object.keys(productAttributes)
                .map(key => ({
                    key,
                    selected: productAttributes[key].length,
                }))
                .reverse();
        }
    } catch (error_) {
        const error = error_ as Error;
        logToSentry(`${error.message} :  Error retrieving attributes from server side`);
    }

    return [];
};

const getUrlAttributes = (urlParam: string): QuickFilter[] => {
    try {
        const searchParams = new URLSearchParams(window.location.href);

        const attributesList = searchParams.get(urlParam);

        if (attributesList) {
            const attributes = attributesList.split(',').map(attribute => {
                const [key, value] = attribute.split(':');
                return { key, selected: value.split('_').length };
            });

            return attributes.reverse();
        }
    } catch (error_) {
        const error = error_ as Error;
        logToSentry(`${error.message} :  Error retrieving attributes from client side`);
    }

    return [];
};

export { getServerAttributes, getUrlAttributes };
