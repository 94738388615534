import {
    GA4EventName,
    type GA4ItemListName,
    type GA4ItemListType,
    type ProductType as TrackingProductType,
    trackEecToggleWishlistItem,
    trackToggleWishlist,
    trackWishlistError,
    type Variant,
    WishlistError,
    WishListEventType,
} from '@jsmdg/tracking';
import { useWishlist, WishlistErrorTypes } from '@jsmdg/wishlist-library';
import { type WishlistResponseError } from '@jsmdg/wishlist-library/dist/esm/client/types';
import { NotificationVariant, useSnackbarContext } from '@jsmdg/yoshi';
import { ProductType } from '../../shared/enums/productType';
import { type Product } from '../../shared/types/searchResponse';
import { type WishlistData } from '../types/wishlist';

type UseWishListToggleRetrun = {
    isOnWishlist: boolean;

    onWishlistButtonClick: () => Promise<void>;
};

const useWishListToggle = (
    product: Product,
    messages: Record<string, string>,
    wishlistData: WishlistData,
    itemListName: GA4ItemListName,
    listType: GA4ItemListType,
    hasLocationFilter?: boolean,
): UseWishListToggleRetrun => {
    const { customerState = { isLoggedIn: false }, initialWishlist, errorLogger } = wishlistData;
    const { toggleWishlistItem, isInWishlist } = useWishlist(customerState, initialWishlist);

    const {
        productId,
        title,
        manufacturerSku,
        price,
        originalPrice,
        productType,
        trackingName,
        trackingPath,
        participants,
        locations,
        url,
    } = product;

    const { openSnackbar } = useSnackbarContext();

    const isOnWishlist = isInWishlist(productId);

    const onWishlistButtonClick = async (): Promise<void> => {
        try {
            await toggleWishlistItem(productId);
            await window.yieldToMainThread();
            const type = isOnWishlist
                ? WishListEventType.RemoveFromWishlist
                : WishListEventType.AddToWishlist;

            trackToggleWishlist({
                type,
                productId,
                productTitle: product.title,
                itemListName,
                listType,
            });

            if (!isOnWishlist) {
                trackEecToggleWishlistItem({
                    product: {
                        id: productId,
                        url,
                        trackingName,
                        manufacturerSku,
                        trackingPath,
                        price: {
                            gross: {
                                amount: Number.parseFloat(price.gross.amount),
                                currencyCode: price.gross.currencyCode,
                            },
                        },
                        originalPrice: originalPrice && {
                            gross: {
                                amount: Number.parseFloat(originalPrice.gross.amount),
                                currencyCode: originalPrice.gross.currencyCode,
                            },
                        },
                        title,
                        rating: product.rating,
                        participants: {
                            min: participants?.min || 0,
                            max: participants?.max || 0,
                        },

                        ...(locations ? { locationCount: locations?.length } : {}),
                        productType: productType as unknown as TrackingProductType,
                        locationIsSelected: hasLocationFilter,
                        discountBadgeId: product.discountBadge?.id,
                        discountBadgeName: product.discountBadge?.text,
                    },
                    quantity: 1,
                    variant: (productType === ProductType.ExperienceProduct
                        ? 'experience_voucher'
                        : productType) as Variant,
                    eventName: GA4EventName.AddToWishlist,
                    itemListName,
                });
            }
        } catch (error) {
            if (
                (error as WishlistResponseError).response?.data?.message ===
                    WishlistErrorTypes.COUNT_EXCEEDED ||
                (error as Error).message === WishlistErrorTypes.COUNT_EXCEEDED
            ) {
                openSnackbar({
                    variant: NotificationVariant.Error,
                    autoHideDuration: 8_000,
                    content: messages.tooManyWishlistArticlesError,
                    a11yTextCloseButton: messages.a11yCloseErrorMessage,
                });
                trackWishlistError(
                    WishlistError.MoreThan99Products,
                    messages.tooManyWishlistArticlesError,
                );
            } else {
                errorLogger(error as Error);
            }
        }
    };

    return { isOnWishlist, onWishlistButtonClick };
};

export { useWishListToggle };
