import classNames from 'classnames';

import {
    PromotionType,
    trackEecPromotionClick,
    trackEecPromotionImpression,
} from '@jsmdg/tracking';
import {
    AlpacaIcon,
    DinnerIcon,
    FlyIcon,
    HealthIcon,
    PaintIcon,
    PureLink,
    SailboatIcon,
    SportIcon,
    SteeringWheelIcon,
    TrampolineIcon,
    TripIcon,
    WaterSportsIcon,
    WaypointIcon,
} from '@jsmdg/yoshi';
import { type PageType } from '../../../shared/enums/pageType';
import { CategoryId } from '../../enums/categoryId';
import { trackingTypeByPage } from '../../enums/trackingPageTypes';
import { CreativeName } from '../../enums/trackingPromotions';
import { enhanceUrlWithSearchParams } from '../../helper/enhanceUrlWithSearchParams';
import { useImpressionTracking } from '../../hooks';
import styles from './Categories.module.scss';

const iconsProps = { size: 40, className: styles.categoryIcon };

const iconsMap = {
    [CategoryId.Fly]: (
        <FlyIcon
            size={iconsProps.size}
            className={classNames(iconsProps.className, styles.flyIcon)}
        />
    ),
    [CategoryId.Trip]: <TripIcon {...iconsProps} />,
    [CategoryId.Car]: <SteeringWheelIcon {...iconsProps} />,
    [CategoryId.Dinner]: <DinnerIcon {...iconsProps} />,
    [CategoryId.IndoorActivities]: <TrampolineIcon {...iconsProps} />,
    [CategoryId.OutdoorActivities]: <SportIcon {...iconsProps} />,
    [CategoryId.RegionActivities]: <WaypointIcon {...iconsProps} />,
    [CategoryId.Animals]: <AlpacaIcon {...iconsProps} />,
    [CategoryId.Art]: <PaintIcon {...iconsProps} />,
    [CategoryId.Health]: <HealthIcon {...iconsProps} />,
    [CategoryId.WaterSport]: <WaterSportsIcon {...iconsProps} />,
    [CategoryId.Adventure]: <SailboatIcon {...iconsProps} />,
};

type CategoryProps = {
    readonly pageType: PageType;
    readonly categoryId: string;
    readonly url: string;
    readonly label: string;
    readonly verticalPosition: number;
    readonly hasNavigationCreative: boolean;
    readonly index: number;
};

const Category = ({
    categoryId,
    hasNavigationCreative,
    index,
    label,
    pageType,
    url,
    verticalPosition,
}: CategoryProps): JSX.Element | null => {
    const IconComponent = iconsMap[categoryId as CategoryId];

    const adaptedVerticalPosition = hasNavigationCreative ? verticalPosition - 1 : verticalPosition;

    const position = `vertical${adaptedVerticalPosition}_horizontal${index + 1}`;

    const promotionTrackingPayload = {
        id: categoryId,
        name: label,
        creative: `iconSlider_${trackingTypeByPage[pageType]}`,
        position,
    };

    const promotionTrackingEventData = {
        creativeName: CreativeName.IconSlider,
        creativeSlot: position,
        promotionName: label,
        promotionId: categoryId,
        locationId: url || '',
        positionHorizontal: `${index + 1}`,
        positionVertical: `${adaptedVerticalPosition}`,
        promotionType: PromotionType.Static,
    };

    const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event?.preventDefault();

        trackEecPromotionClick(promotionTrackingPayload, promotionTrackingEventData);
        window.location.href = enhanceUrlWithSearchParams(url);
    };

    const setImpressionTrackedElement = useImpressionTracking(() =>
        trackEecPromotionImpression([promotionTrackingPayload], promotionTrackingEventData),
    );

    return (
        <PureLink
            href={url}
            key={categoryId}
            className={classNames(
                styles.category,
                'd-flex flex-column w-100 pb-2-5x font-complementary align-items-center text-decoration-none',
            )}
            onClick={onLinkClick}
            ref={setImpressionTrackedElement}
            internal
        >
            {IconComponent}
            <h6 className="text-center my-1x">{label}</h6>
        </PureLink>
    );
};

export { Category };
