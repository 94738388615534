import { Breakpoint, type SliderConfig } from '@jsmdg/yoshi';

type ConfigByBreakpoint = {
    [configKey in Breakpoint]: SliderConfig;
};

const createConfig = (
    spaceBetween: number,
    slidesPerView: number,
    slidesPerGroup: number,
    showPagination: boolean,
    showNavigation: boolean,
): SliderConfig => ({
    spaceBetween,
    slidesPerView,
    slidesPerGroup,
    showPagination,
    showNavigation,
});

const commonConfig = {
    [Breakpoint.XS]: createConfig(16, 3, 3, true, true),
    [Breakpoint['2XS']]: createConfig(16, 2, 2, true, true),
    [Breakpoint['3XS']]: createConfig(16, 2, 2, true, true),
};

export const pageTilesConfig: ConfigByBreakpoint = {
    [Breakpoint.SM]: createConfig(24, 3, 3, true, true),
    [Breakpoint.MD]: createConfig(24, 3, 3, true, true),
    [Breakpoint.XL]: createConfig(32, 4, 4, false, false),
    [Breakpoint.LG]: createConfig(24, 4, 4, false, false),
    ...commonConfig,
};

export const pageTilesConfigBundleBox: ConfigByBreakpoint = {
    [Breakpoint.SM]: createConfig(24, 4, 4, true, true),
    [Breakpoint.MD]: createConfig(24, 4, 4, true, true),
    [Breakpoint.XL]: createConfig(32, 4, 4, true, true),
    [Breakpoint.LG]: createConfig(24, 4, 4, true, true),
    ...commonConfig,
};
