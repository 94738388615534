import { Suspense } from 'react';
import loadable from '@loadable/component';

import { getLastSeenProductIdsStorage } from '@jsmdg/browser-storage';
import { type PageType } from '../../../shared/enums/pageType';
import { ProductSliderFallback } from '../ProductSlider/ProductSliderFallBack';

const LastSeenProductSliderContent = loadable(
    async () => import('./LastSeenProductSliderContent'),
    {
        fallback: <ProductSliderFallback />,
    },
);

type LastSeenProductSliderProps = {
    readonly lazyLoad: boolean;
    readonly pageType: PageType;
    readonly verticalPosition?: number;
};

const LastSeenProductSlider = ({
    lazyLoad,
    pageType,
    verticalPosition,
}: LastSeenProductSliderProps): JSX.Element | null => {
    const lastSeenProductIds = getLastSeenProductIdsStorage().get();

    if (!lastSeenProductIds?.length) {
        return null;
    }

    return (
        <Suspense fallback={<ProductSliderFallback />}>
            <LastSeenProductSliderContent
                lazyLoad={lazyLoad}
                pageType={pageType}
                verticalPosition={verticalPosition}
                productIds={lastSeenProductIds}
            />
        </Suspense>
    );
};

export { LastSeenProductSlider };
