/* eslint-disable complexity */
import { type ReactNode } from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Accordion, ButtonSize, Wysiwyg } from '@jsmdg/yoshi';
import { ContentType } from '../../../shared/enums/contentType';
import { PageType } from '../../../shared/enums/pageType';
import { type ContentBlock } from '../../../shared/types/contentBlock';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type PageContent as PageContentType } from '../../../shared/types/pageContent';
import { type PageDocument } from '../../../shared/types/pageDocument';
import { TrackingCreativePrefix } from '../../enums/trackingCreativePrefix';
import { useCreatives } from '../../hooks/useCreatives';
import { type InitialPageFilterType } from '../../types';
import { BestsellerSlider } from '../BestsellerSlider';
import { CardGroup } from '../CardGroup';
import { Categories } from '../Categories';
import { DiscountCodeBox } from '../DiscountCodeBox';
import { HeroImage } from '../HeroImage';
import { HeroStage } from '../HeroStage';
import { HighlightSlider } from '../HighlightSlider';
import { ImageAndText } from '../ImageAndText';
import { ImageSlider } from '../ImageSlider';
import { LastSeenProductSlider } from '../LastSeenProductSlider';
import { LinkBox } from '../LinkBox';
import { LinkButtonBar } from '../LinkButtonBar';
import { PageSliderWrapper } from '../PageSliderWrapper';
import { PageTiles } from '../PageTiles';
import { ProductDescription } from '../ProductDescription/ProductDescription';
import { ProductList } from '../ProductList/ProductList';
import { TabGroup } from '../TabGroup';
import { USPSlider } from '../USPSlider';
import styles from './Page.module.scss';

const ContactForm = loadable(async () => import('../ContactForm/ContactForm'));
const NewsletterOptIn = loadable(async () => import('../Newsletter/OptIn/NewsletterOptIn'));
const NewsletterSubscribe = loadable(
    async () => import('../Newsletter/Subscribe/NewsletterSubscribe'),
);
const NewsletterUnsubscribe = loadable(
    async () => import('../Newsletter/Unsubscribe/NewsletterUnsubscribe'),
);

type PageContentProps = {
    readonly document?: PageDocument;
    readonly pageType: PageType;
    readonly pageId?: string;
    readonly numProductListings?: number;
};

const PageContent = ({
    document,
    numProductListings,
    pageId = '',
    pageType,
}: PageContentProps): JSX.Element | null => {
    const {
        heroStageCreatives,
        navigationCreatives,
        productTileCreatives,
        selectionBlockCreatives,
    } = useCreatives();
    const { isMydays, tenant } = useFragmentContext<FragmentContext>();

    // eslint-disable-next-line regexp/strict
    const searchPlaceholder = /{{TENANT_PLACEHOLDER}}/g;
    const tenantReplacement = tenant?.slice(-2).toLowerCase();
    const hasNavigationCreative = navigationCreatives.length > 0;

    let verticalPosition = hasNavigationCreative ? 1 : 0;
    let productListingIndex = 0;
    let wysiwygCounter = 0;

    const isHomePage = pageType === PageType.Home;

    const findUspPosition = (): number => {
        if (!document?.contents) return -1;

        const reversedContents = document.contents.slice().reverse();

        const matchingIndex = reversedContents.findIndex(content =>
            [ContentType.Wysiwyg, ContentType.PageSlider].includes(content.type),
        );

        return matchingIndex > -1 ? document.contents.length - 1 - matchingIndex : -1;
    };

    const renderPageContentItems = (content: PageContentType, index: number): ReactNode => {
        switch (content.type) {
            case ContentType.PageSlider:
                verticalPosition += 1;
                return (
                    <PageSliderWrapper
                        slides={content.data || []}
                        headline={content.title}
                        verticalPosition={verticalPosition}
                        hasNavigationCreative={hasNavigationCreative}
                        pageType={pageType}
                        crossLinkPageTitle={content?.crossLinkPageTitle}
                        crossLinkPageRef={content?.crossLinkPageRef}
                        trackingPrefix={TrackingCreativePrefix.PageSlider}
                        trackingPath={document?.trackingPath}
                    />
                );
            case ContentType.Image:
                return (
                    <HeroImage
                        alt={content.alt || document?.headline || ''}
                        src={content.url}
                        srcset={content.srcset}
                        sizes={content.sizes}
                        width={823}
                        height={344}
                        lazyLoad={false}
                    />
                );
            case ContentType.Wysiwyg:
                wysiwygCounter += 1;

                // only render this component for the first type
                if (pageType === PageType.ProductListingPage && wysiwygCounter === 1) {
                    return <ProductDescription value={content.value} />;
                }

                return <Wysiwyg content={content.value} />;

            case ContentType.ProductListQuery: {
                if (productTileCreatives.length || selectionBlockCreatives.length) {
                    verticalPosition += 1;
                }

                productListingIndex += 1;

                const initialPageFilter: InitialPageFilterType = {
                    geoDistanceFilter: content.geoDistanceFilter ? content.geoDistanceFilter : {},
                    travelNightsFilter: content.travelNightsFilter
                        ? content.travelNightsFilter
                        : {},
                    priceFilter: content.priceFilter ?? {},
                    participants: content.participantsFilter ?? null,
                    topCountryCodes: content.topCountryCodes ?? null,
                    productAttributes: {},
                };

                return (
                    <ProductList
                        indexId={`product-list-${productListingIndex}`}
                        initialSearch={content.initialSearch}
                        initialPageFilter={initialPageFilter}
                        lazyLoad
                        hasUiFilters={content.hasUiFilters}
                        showLocationFilter={content.showLocationFilter}
                        verticalPosition={verticalPosition}
                        pageType={pageType}
                        pageId={pageId}
                        pageTitle={document?.headline}
                        isLastList={productListingIndex === numProductListings}
                        trackingName={document?.trackingName}
                    />
                );
            }

            case ContentType.ImageAndText:
                return (
                    <ImageAndText
                        image={content.image}
                        imagePosition={content.imagePosition}
                        content={content.content}
                        link={content.link}
                        target={content.target || ''}
                        linkLabel={content.linkLabel}
                        lazyLoad
                    />
                );
            case ContentType.ImageSlider: {
                verticalPosition += 1;
                const initialVerticalPosition = verticalPosition;
                if (!isMydays) verticalPosition += 1;

                if (isHomePage) {
                    return (
                        <HeroStage
                            galleryImages={content.galleryImages}
                            categories={content.categories}
                            heroStageCreatives={heroStageCreatives}
                            hasNavigationCreative={hasNavigationCreative}
                            verticalPosition={verticalPosition}
                        />
                    );
                }

                return (
                    <>
                        <ImageSlider
                            galleryImages={content.galleryImages}
                            autoSlide={content.autoSlide}
                            verticalPosition={initialVerticalPosition}
                            hasNavigationCreative={hasNavigationCreative}
                            pageType={pageType}
                        />
                        {!isMydays && (
                            <Categories
                                pageType={pageType}
                                verticalPosition={verticalPosition}
                                hasNavigationCreative={hasNavigationCreative}
                            />
                        )}
                    </>
                );
            }

            case ContentType.LinkBoxWidget:
                return <LinkBox boxTitle={content.title || ''} pageLinks={content.data} />;
            case ContentType.LastSeen:
                verticalPosition += 1;
                return (
                    <LastSeenProductSlider
                        lazyLoad
                        pageType={pageType}
                        verticalPosition={verticalPosition}
                    />
                );
            case ContentType.PageTileWidget:
                verticalPosition += 1;

                return (
                    <>
                        <PageTiles
                            identifier={`${content.type}-${index}`}
                            tiles={content.pageTiles}
                            pageType={pageType}
                            isNextToNavigation={index < 2}
                            verticalPosition={verticalPosition}
                            hasNavigationCreative={hasNavigationCreative}
                            trackingPrefix={TrackingCreativePrefix.PageTile}
                        />
                        {!isMydays && (
                            <Categories
                                pageType={pageType}
                                verticalPosition={verticalPosition}
                                hasNavigationCreative={hasNavigationCreative}
                                withBlockMargin
                            />
                        )}
                    </>
                );
            case ContentType.CollabsibleGroup: {
                const items = content.collapsibles.map(
                    (collapsible: ContentBlock, itemIndex: number) => {
                        const collapsibleContent = collapsible.body.replaceAll(
                            searchPlaceholder,
                            tenantReplacement,
                        );
                        // eslint-disable-next-line react/no-array-index-key
                        const body = <Wysiwyg key={itemIndex} content={collapsibleContent} />;

                        return (
                            <Accordion.Item title={collapsible.caption} key={collapsible.caption}>
                                {body}
                            </Accordion.Item>
                        );
                    },
                );

                return <Accordion autoClose>{items}</Accordion>;
            }

            case ContentType.Bestseller:
                verticalPosition += 1;
                return (
                    <BestsellerSlider
                        initialSearchResult={content.initialSearchResult}
                        moreResultsLink={content.moreResultsLink || ''}
                        lazyLoad
                        pageType={pageType}
                        verticalPosition={verticalPosition}
                    />
                );

            case ContentType.Highlight:
                verticalPosition += 1;
                return (
                    <HighlightSlider
                        initialSearchResult={content.initialSearchResult}
                        moreResultsLink={content.moreResultsLink || ''}
                        lazyLoad
                        pageType={pageType}
                        verticalPosition={verticalPosition}
                    />
                );
            case ContentType.DiscountCodeWidget:
                return (
                    <DiscountCodeBox
                        placeholder={content.placeholder}
                        buttonText={content.buttonText}
                        successMessage={content.successMessage}
                        description={content.description}
                        code={document?.discountCode}
                    />
                );
            case ContentType.CardGroup:
                return (
                    <CardGroup cards={content.cards} showOutline={content.showOutline} lazyLoad />
                );

            case ContentType.TabGroup:
                return <TabGroup tabs={content.tabs} selectedTab={document?.selectedTab} />;
            case ContentType.ContactForm:
                return <ContactForm />;
            case ContentType.ButtonBar:
                return (
                    <LinkButtonBar
                        alignment={content.alignment}
                        size={content.size === 'small' ? ButtonSize.Small : ButtonSize.Large}
                        variant={content.variant}
                        buttons={content.buttons}
                    />
                );
            case ContentType.NewsletterOptIn:
                return (
                    <NewsletterOptIn
                        content={content.content}
                        predictedUserId={document?.newsletter?.predictedUserId || ''}
                        formHeading={content.formHeading}
                        formButtonLabel={content.formButtonLabel}
                        formAdditionalText={content.formAdditionalText}
                        formSuccessText={content.formSuccessText}
                    />
                );
            case ContentType.NewsletterSubscribeForm:
                return (
                    <NewsletterSubscribe
                        source={document?.newsletter?.source}
                        email={document?.newsletter?.email}
                        caption={content.caption}
                        emailPlaceholder={content.emailPlaceholder}
                        consentText={content.consentText}
                        buttonLabel={content.buttonLabel}
                        additionalText={content.additionalText}
                        successText={content.successText}
                        withNameFields={isMydays}
                    />
                );
            case ContentType.NewsletterUnsubscribeForm:
                return (
                    <NewsletterUnsubscribe
                        confirmationText={content.confirmationText}
                        formText={content.formText}
                        email={document?.newsletter?.email}
                        additionalData={document?.newsletter?.additionalData}
                    />
                );
            default:
                return <div>Unknown element</div>;
        }
    };

    if (!document?.contents) return null;

    const uspPosition = findUspPosition();

    return (
        <>
            {document?.contents.map((content, index) => {
                const key = `row-${index}`;
                const isPLPImageContent =
                    pageType === PageType.ProductListingPage && content.type === ContentType.Image;
                const isImageSLiderOrWysiwyg =
                    content.type === ContentType.ImageSlider ||
                    content.type === ContentType.Wysiwyg;
                const showUSPBanner = isHomePage && uspPosition === index;

                return (
                    <div
                        key={key}
                        className={classNames('grid', {
                            'd-none d-sm-block': isPLPImageContent,
                        })}
                    >
                        <div
                            className={classNames('g-col-12', styles.row, {
                                [styles.homeRow]: isHomePage,
                                'mb-0': isImageSLiderOrWysiwyg && isHomePage,
                                'mt-0': content.type === ContentType.ImageSlider && isHomePage,
                            })}
                        >
                            {/* show USP for all tenants except JS_AT before last Wysiwyg */}
                            {showUSPBanner && content.type === ContentType.Wysiwyg && <USPSlider />}
                            {renderPageContentItems(content, index)}
                            {/* show USP for JS_AT only after last PageSlider */}
                            {showUSPBanner && content.type === ContentType.PageSlider && (
                                <USPSlider />
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export { PageContent };
