import { type Hit } from '@algolia/client-search';

import {
    type AlgoliaProduct,
    type Location,
    type Price,
    type Product,
} from '../types/searchResponse';
import { getClosestLocation } from './algoliaHelpers';

function mapToPrice({ grossAmount, currencyCode }: AlgoliaProduct['price']): Price {
    return {
        gross: {
            amount: `${grossAmount}`,
            currencyCode,
        },
    };
}

function isProduct(product: AlgoliaProduct | Product): product is Product {
    return !('productInformation' in product);
}

export function mapAlgoliaProductHitToProduct(
    algoliaProductHit: Hit<AlgoliaProduct | Product>,
    showLocation?: boolean,
): Product {
    if (isProduct(algoliaProductHit)) {
        return algoliaProductHit;
    }

    const {
        productId,
        title,
        url,
        productType,
        manufacturerSku,
        price,
        trackingName,
        trackingPath,
        images,
        inPreview,
        isTestProduct,
        sellOption,
        productInformation,
        participants,
        originalPrice,
        classifications,
        rating,
        discountBadge,
        isSellable,
        hasPriceRange,
    } = algoliaProductHit;

    const locations = algoliaProductHit.locations?.length
        ? algoliaProductHit.locations.map(
              (location): Location => ({
                  name: location.city,
                  lat: location.position.lat,
                  long: location.position.lon,
              }),
          )
        : undefined;

    return {
        title,
        url,
        productId,
        productType,
        manufacturerSku,
        price: mapToPrice(price),
        trackingName,
        trackingPath,
        images,
        inPreview,
        isTestProduct,
        sellOption,
        services: productInformation.services,
        participants,
        originalPrice: originalPrice ? mapToPrice(originalPrice) : undefined,
        classifications,
        locations,
        closestLocation: showLocation ? getClosestLocation(algoliaProductHit) : undefined,
        rating,
        discountBadge,
        isSellable,
        hasPriceRange,
    };
}
