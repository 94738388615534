import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { Link as LinkButton, LinkVariant } from '@jsmdg/yoshi';
import { type BaseLink } from '../../../shared/types/pageContent';

type LinkBoxProps = {
    readonly boxTitle: string;
    readonly pageLinks: BaseLink[];
};

const LinkBox = ({ boxTitle, pageLinks }: LinkBoxProps): JSX.Element => {
    const [hideMoreLinks, setHideMoreLinks] = useState(pageLinks.length > 12);

    return (
        <>
            <h3>{boxTitle}</h3>
            {pageLinks.map((pageLink, index) => {
                const siblingLinkClass = classNames(
                    'mb-0-5x text-truncate',
                    index > 11 && hideMoreLinks ? 'd-none' : 'd-block',
                );

                return (
                    <LinkButton
                        variant={LinkVariant.Grey}
                        href={pageLink.url}
                        className={siblingLinkClass}
                        key={pageLink.title}
                        internal
                    >
                        {pageLink.title}
                    </LinkButton>
                );
            })}
            {pageLinks.length > 12 && (
                <LinkButton
                    variant={LinkVariant.Grey}
                    onClick={() => setHideMoreLinks(!hideMoreLinks)}
                    className="mt-1x"
                    internal
                >
                    {hideMoreLinks && <FormattedMessage defaultMessage="Mehr anzeigen" />}
                    {!hideMoreLinks && <FormattedMessage defaultMessage="Weniger anzeigen" />}
                </LinkButton>
            )}
        </>
    );
};

export { LinkBox };
