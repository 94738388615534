import { Suspense } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import loadable from '@loadable/component';

import { GA4FeatureCategory, GA4ItemListName } from '@jsmdg/tracking';
import { type PageType } from '../../../shared/enums/pageType';
import { type SearchResult } from '../../../shared/types/searchResult';
import { ProductSliderFallback } from '../ProductSlider/ProductSliderFallBack';

const ProductSlider = loadable(async () => import('../ProductSlider/ProductSlider'));

type HighlightSliderProps = {
    readonly initialSearchResult: SearchResult;
    readonly moreResultsLink: string;
    readonly lazyLoad: boolean;
    readonly pageType: PageType;
    readonly verticalPosition?: number;
};

const messages = defineMessages({
    highlightProducts: { defaultMessage: 'Highlights' },
    highlightShowMore: { defaultMessage: 'Mehr entdecken' },
});

const HighlightSlider = ({
    initialSearchResult,
    lazyLoad,
    moreResultsLink,
    pageType,
    verticalPosition,
}: HighlightSliderProps): JSX.Element | null => {
    const intl = useIntl();

    const products = initialSearchResult.items;

    if (!products.length) {
        return null;
    }

    return (
        <Suspense fallback={<ProductSliderFallback />}>
            <ProductSlider
                products={products}
                title={intl.formatMessage(messages.highlightProducts)}
                urlTitle={intl.formatMessage(messages.highlightShowMore)}
                url={moreResultsLink}
                trackingCategory={GA4FeatureCategory.ProductList}
                trackingListName={GA4ItemListName.Highlight}
                dataTestId="highlight-slider"
                lazyLoad={lazyLoad}
                pageType={pageType}
                verticalPosition={verticalPosition}
            />
        </Suspense>
    );
};

export { HighlightSlider };
