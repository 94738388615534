import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { Badge, Button, ButtonSize, FilterHorizontalIcon, isSSR, ScrollSlider } from '@jsmdg/yoshi';
import { StaticFilter } from '../../../../shared/enums/staticFilter';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type ProductAttributes } from '../../../../shared/types/search';
import { getServerAttributes, getUrlAttributes } from '../../../helper/quickFilters';
import { type QuickFilter } from '../../../types';
import styles from './QuickFilters.module.scss';

const INITIAL_FILTERS_COUNT = 3;

const PRODUCT_ATTRIBUTES = 'productAttributes';

const PRICE_FILTER = {
    key: StaticFilter.Price,
    name: 'Preis',
};

const getUpdatedAttributes = (
    fixedFilters: QuickFilter[],
    facets: FacetFilter[],
    filter: QuickFilter | null,
    productAttributes?: ProductAttributes,
): QuickFilter[] => {
    let attributes = isSSR
        ? getServerAttributes(productAttributes)
        : getUrlAttributes(PRODUCT_ATTRIBUTES);

    attributes = [
        ...attributes,
        ...fixedFilters.filter(
            fixedFilter => !attributes.some(attribute => attribute.key === fixedFilter.key),
        ),
    ];

    if (filter) {
        attributes = attributes.filter(item => item.key !== filter.key);

        if (filter.key === StaticFilter.Price) {
            if (filter?.selected > 0) {
                attributes.unshift({ ...PRICE_FILTER, selected: filter?.selected });
            } else {
                attributes.push({ ...PRICE_FILTER, selected: 0 });
            }
        } else if (filter?.selected > 0) {
            attributes.unshift({ key: filter?.key, selected: filter?.selected });
        } else if (fixedFilters.findIndex(item => item.key === filter.key) !== -1) {
            attributes.push({ key: filter?.key, selected: 0 });
        }
    }

    attributes = attributes.map(attribute => {
        const matchingFilter = facets.find(facet => facet.attribute === attribute.key);

        if (matchingFilter) {
            return { ...attribute, name: matchingFilter.name };
        }

        return attribute;
    });

    return attributes;
};

type QuickFiltersProps = {
    readonly facets: FacetFilter[];
    readonly productAttributes?: ProductAttributes;
    readonly isInitialPrice: boolean;
    readonly updatedFilter: QuickFilter | null;
    readonly selectFilterPanel: (filterId: string, filterName?: string) => void;
};

const QuickFilters = ({
    facets,
    isInitialPrice,
    productAttributes,
    selectFilterPanel,
    updatedFilter,
}: QuickFiltersProps): JSX.Element => {
    const onFilterClick = (id: string, name?: string): void => {
        selectFilterPanel(id, name);

        const element = document.querySelector(`.${styles.sliderContainer}`);
        if (element) element.scrollTo({ left: 0 });
    };

    const fixedFilters = [
        { ...PRICE_FILTER, selected: isInitialPrice ? 0 : 1 },
        ...(facets?.slice(0, INITIAL_FILTERS_COUNT).map(item => ({
            key: item.attribute,
            name: item.name,
            selected: 0,
        })) || []),
    ];

    const attributes = getUpdatedAttributes(fixedFilters, facets, updatedFilter, productAttributes);

    return (
        <div className={classNames('d-sm-none w-100', styles.quickFilters)}>
            <h2 className={classNames(styles.title, 'py-0')}>
                <FormattedMessage defaultMessage="Weitere Filter" />
            </h2>
            <ScrollSlider
                shouldShowProgressBar={false}
                className={styles.slider}
                containerClassname={styles.sliderContainer}
            >
                <Button
                    className={classNames('position-relative mr-1x px-2x', styles.filterButton)}
                    size={ButtonSize.Small}
                    onClick={() => onFilterClick('')}
                    dataTestId="main-button"
                >
                    <FilterHorizontalIcon className={styles.icon} />
                </Button>
                {attributes?.map(facet => {
                    return (
                        <Button
                            key={facet.key}
                            className={classNames(
                                'position-relative mr-1x px-2x',
                                styles.filterButton,
                                {
                                    [styles.active]: !!facet?.selected,
                                },
                            )}
                            size={ButtonSize.Small}
                            onClick={() => onFilterClick(facet.key, facet.name)}
                            dataTestId={`quick-filter-${facet.key}`}
                        >
                            {facet.name}
                            {!!facet?.selected && (
                                <Badge
                                    className={classNames(
                                        'position-absolute rounded-circle',
                                        styles.filterCount,
                                    )}
                                >
                                    {facet?.selected}
                                </Badge>
                            )}
                        </Button>
                    );
                })}
            </ScrollSlider>
        </div>
    );
};

export { QuickFilters };
